import React from 'react';
import RoofFinder from "./Map";

const App = () => {
    return (
        <div>
            <RoofFinder/>
        </div>
    );
};

export default App;
