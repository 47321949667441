import React, {useState, useEffect, useRef} from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import * as turf from "@turf/turf";
import {styled} from "styled-components";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import {lineOffset} from "@turf/turf";
import axios from 'axios';
import * as numeral from 'numeral'

mapboxgl.accessToken = 'pk.eyJ1IjoidmxhZHlzbGF2YSIsImEiOiJjbGVyaDk0YXgwdzcyM3JydmtydjVnaHFqIn0.oCRXnRr2mp8-1tvbIvr0TQ';

const RoofFinder = () => {
    const geocoderContainer = useRef(null);
    const mapContainer = useRef(null);
    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [draw, setDraw] = useState(null);
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [editingSelectedFeatures, setEditingSelectedFeatures ] = useState([]);
    const [step, setStep] = useState(1);
    const [editMode, setEditMode] = useState(true);

    const [selectedLoc,setSelectedLoc] = useState('Adress...');

    const [queryParams, setQueryParams] = useState({});

    const [imgView, setImgView] = useState('https://picsum.photos/200/300');

    const [area, setArea] = useState(null);

    const [areaShort, setAreaShort] = useState(null);


    useEffect(() => {
        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            placeholder: 'Enter an address'
        });

        geocoder.addTo('#geocoder-container');
        geocoder.on('result',(event)=>{
            //console.log(event.result)
            const [lng, lat] = event.result.center;
            handleSearch([lng, lat])
        });
        geocoder.on('clear', handleGeocoderClear);
    }, []);

    const getPlaceName = (lng,lat) => {
        fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?types=address&access_token=${mapboxgl.accessToken}`)
        .then(response => response.json())
        .then(data => {
            //setSelectedLoc('hasa')
            //console.log(data)
            //let res = 

            let locationName = '';

            console.log(typeof(data.features[0]))
            if(data.features[0] != undefined){
                locationName = `${data.features[0].place_name}`
            }
            else if(data.features[0] == undefined){
                locationName = 'Unnamed Location'
            }

            geocoderContainer.current.children[0].children[1].value = locationName;

            setSelectedLoc(locationName);

        });
    }


    const handleSearch = ([lng, lat],locName) => {
        //let
        
        
        getPlaceName(lng, lat)
        console.log(selectedLoc)
        //console.log(event.result.center);
        handleGeocoderClear();
        //const [lng, lat] = event.result.center;
        const mapInstance  = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-v9',
            center: [0, 0],
            scrollZoom: false,
            preserveDrawingBuffer: true,
        });

        const nav = new mapboxgl.NavigationControl({
            showCompass: false
        });

        mapInstance.addControl(nav, 'top-left');

        if (mapInstance) {
            mapInstance.flyTo({
                center: [lng, lat],
                zoom: 18,
            });

            const drawInstance = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: true,
                    trash: true
                },
                defaultMode: 'draw_polygon',
                styles: [
                    {
                        id: 'gl-draw-polygon-fill-inactive',
                        type: 'fill',
                        paint: {
                            'fill-color': 'rgba(253, 186, 55, 0.5)'
                        }
                    },
                    {
                        id: 'gl-draw-polygon-stroke-inactive',
                        type: 'line',
                        paint: {
                            'line-color': 'rgba(253, 186, 55, 1)',
                            'line-dasharray': [2, 2],
                            'line-width': 2
                        }
                    },
                    {
                        id: 'gl-draw-polygon-and-line-vertex-inactive',
                        type: 'circle',
                        paint: {
                            'circle-radius': 5,
                            'circle-color': 'rgba(253, 186, 55, 1)'
                        }
                    }
                ]
            });

            mapInstance.addControl(drawInstance , 'bottom-left');

            mapInstance.on('draw.create', function (e) {
                setEditingSelectedFeatures(prevState => [...prevState, e.features[0]]);
            });

            mapInstance.on('draw.update', (e) => {
                if (editMode && e.features[0] && e.action === 'change_coordinates') {
                    setEditingSelectedFeatures(prevState => {
                        const existingFeatureIndex = prevState.findIndex(feature => feature.id === e.features[0].id);
                        if (existingFeatureIndex !== -1) {
                            const updatedFeatures = [...prevState];
                            updatedFeatures[existingFeatureIndex] = e.features[0];
                            return updatedFeatures;
                        } else {
                            return [...prevState, e.features[0]];
                        }
                    });
                }
            });

            mapInstance.on('draw.delete', (e) => {
                const deletedFeatureId = e.features[0].id;
                setEditingSelectedFeatures(prevState => prevState.filter(feature => feature.id !== deletedFeatureId));
            });

            const markerInstance = new mapboxgl.Marker({
                color: 'rgba(253, 186, 55, 1)',
                draggable: false
            }).setLngLat([lng, lat])
                .addTo(mapInstance);

            setMarker(markerInstance);
            setMap(mapInstance);
            setDraw(drawInstance);
        }
    };

    const removeRoofLayer = () => {
        selectedFeatures.forEach((feature) => {
            const layerId = `roof-${feature.id}`;
            if (map?.getLayer(layerId)) {
                map?.removeLayer(layerId);
                map?.removeSource(layerId);
            }
        });
    };


    const addRoofLayer = () => {
        selectedFeatures.forEach((feature) => {
            const layerId = `roof-${feature.id}`;
            map?.addSource(layerId, {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': [feature.geometry.coordinates[0]]
                    }
                }
            });

            map?.addLayer({
                'id': layerId,
                'type': 'fill',
                'source': layerId,
                'paint': {
                    'fill-color': '#FDBA37',
                    'fill-opacity': 0.8
                }
            });
        });
    };

    const handleGeocoderClear = () => {
        map?.remove()
        setDraw(null);
        setMap(map);
        setSelectedFeatures([]);
        mapContainer.current.innerHTML = "";
        setStep(1);
        setEditMode(true);
    };

    const handleSave = () => {
        let totalArea = 0;
        selectedFeatures.forEach((feature) => {
            const area = turf.area(feature);
            const rounded_area = Math.round(area * 100) / 100;
            totalArea += rounded_area;
        });
        console.log('Total Area:', totalArea);

        totalArea = Math.round(((totalArea * 10.7639) + Number.EPSILON) * 100) / 100;

        //saveDatatoDB(totalArea)
        setArea(totalArea);
        const formattedNumber = numeral(totalArea).format('0.0a');
        setAreaShort(formattedNumber);

        console.log(formattedNumber)

        //const dataToSend = { area: totalArea };
        //window.parent.postMessage(dataToSend, '*');

        setStep(2);
    };

    useEffect(() => {
        // Parse the URL and get the search params
        const urlParams = new URLSearchParams(window.location.search);
        
        // Create an object to store the query parameters
        const params = {};
    
        // Loop through the search params and store them in the object
        for (const [key, value] of urlParams.entries()) {
          params[key] = value;
        }

        console.log(params)
    
        // Update the state with the query parameters
        setQueryParams(params);
    }, []);

    useEffect(() => {
        if(queryParams.lng !== undefined && queryParams.lat !== undefined){
            //setSelectedLoc(queryParams.locName)
            handleSearch([queryParams.lng,queryParams.lat])
        }
       
    },[queryParams])
    

    const saveDatatoDB = (area) => {

        const endpointURL = 'https://mid-server.onrender.com/savedata';
    
        // Sending a POST request with axios.
        axios.post(endpointURL, {id: queryParams.id,value: area})
          .then((response) => {
            console.log('Response:', response.data);
            // Do something with the response, if needed.
            alert('saved')
          })
          .catch((error) => {
            console.error('Error:', error);
            // Handle error, if any.
            alert('could not save data')
          });
    };
    

    const handleSaveEdit = () => {
        setSelectedFeatures([...editingSelectedFeatures]);
        setEditingSelectedFeatures([]);
        setEditMode(false);
        disableControls();
        marker.remove();
        handleSnapshot();
    };

    useEffect(() => {
        !editMode && selectedFeatures.length && addRoofLayer();
        editMode && setEditingSelectedFeatures(selectedFeatures);
    }, [selectedFeatures]);


    const handleCancelEdit = () => {
        setEditMode(false);
        addRoofLayer();
        disableControls();
    };

    const handleEdit = () => {
        setEditMode(true);
        removeRoofLayer();
        map?.addControl(draw, 'bottom-left');
        selectedFeatures.forEach((feature) => {
            const initialPolygon = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: [feature.geometry.coordinates[0]],
                },
                properties: {},
            };
            draw.changeMode('simple_select');
            draw.add(initialPolygon);
        });
        setSelectedFeatures([]);
        setSelectedFeatures((prevState) => {
            const updatedFeatures = [...prevState, ...draw.getAll().features];
            return updatedFeatures;
        });
    };


    const disableControls = () => {
        map.removeControl(draw);
    };

    const handleSnapshot = () => {
        if(map !== undefined && map !== null){
            let snapShot = map.getCanvas().toDataURL();
            console.log(snapShot);
            setImgView(snapShot)
        }
    };

    // const handleKeyDown = (e) => {
    //     if(e.key === "s"){
    //         console.log('pressed s')
    //         handleSnapshot()
    //     }
    // }

    const handleCP = () => {
        const dataToSend = { propt: 'commercial', snapshot: imgView, area: area, address: selectedLoc, areaFmtd: areaShort};
        console.log(dataToSend)
        window.parent.postMessage(dataToSend, '*');
    }

    const handleRP = () => {
        const dataToSend = { propt: 'residential', snapshot: imgView, area: area, address: selectedLoc, areaFmtd: areaShort};
        console.log(dataToSend)
        window.parent.postMessage(dataToSend, '*');
    }

    return (
        <RoofFinderContainer>
            <h2>Calculate My Roof</h2>

            <p className='tagling'>Simplify your roofing process with instant estimates and seamless online checkout, from inquiry to completion.</p>

            {/* must do */}
            <div id="geocoder-container" className="geocoder-container" ref={geocoderContainer} />
            <div className="map-container" ref={mapContainer} />

            {map && editMode && step === 1 && (
                    <div className="btns-container">
                        <button onClick={handleCancelEdit}>Cancel</button>
                        <button disabled={!editingSelectedFeatures?.length} onClick={handleSaveEdit}>Save Changes</button>
                    </div>
            )}

            {map && step === 1 && !editMode && (
                <div className="btns-container">
                <button disabled={!selectedFeatures?.length} onClick={handleSave}>Continue</button>
                <button onClick={handleEdit}>Edit Roofs</button>
                </div>
            )}

            {map && step === 2 && (
                <div className="bottom-container">
                <p>Please, choose between the options below:</p>
                <div className="btns-container">
                <button onClick={handleCP}>Commercial Property</button>
                <button onClick={handleRP}>Residential Property</button>
                </div>
                </div>
            )}

           {/* {imgView && (<img src={imgView} />)} */}
        </RoofFinderContainer>
    );
};

const RoofFinderContainer = styled.div`
  width: 1127px;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-family: 'PolySans Trial-Bulky';
    font-weight: 700;
    font-size: 55px;
    line-height: 66px;
    text-align: center;
    color: #101F3E;
    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  p {
    display: none;
    margin-top: 9px;
    margin-bottom: 32px;
    color: #0E2652;
    text-align: center;
    font-family: PolySans Trial-Slim;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px; 
    @media (max-width: 768px) {
        display: block;
    }
  }

  .map-container {
    width: 1127px;
    max-width: 100%;
    height: 583px;
    border-radius: 15px;
    margin: 90px auto;
    @media (max-width: 768px) {
      height: 343px;
      margin: 30px auto;
    }
  }
  .bottom-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    p {
      font-family: 'PolySans Trial-Slim';
      font-weight: 300;
      font-size: 28px;
      line-height: 16px;
      color: #101F3E;
      @media (max-width: 768px) {
       display: none;
      }
    }
  }
  .btns-container {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: center;
    margin-bottom: 151px;
    button {
      height: 60px;
      padding: 15px;
      border: 2px solid #FDBA37;
      font-family: 'PolySans Trial-Neutral';
      font-weight: 400;
      font-size: 28px;
      line-height: 27px;
      color: #101F3E;
      cursor: pointer;
      background-color: #FFFFFF;
      transition: background-color 0.3s ease;
      &:disabled:hover {
        background-color: #FFFFFF;
      }
      &:hover {
        background: #FDBA37;
      }
      &:disabled {
        opacity: 0.7;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .geocoder-container {
    display: flex;
    justify-content: center;
  }
  
`;


export default RoofFinder;
